import React, { useEffect } from "react";
import {
  Layout,
  PrivateRoute
} from "../components";
import "react-table/react-table.css";
import { Router } from "@reach/router";

import OpsScreenWrapper from "../views/opsScreen/opsScreenTabs";

const App = () => {  
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute default component={OpsScreenWrapper} path="/" pagetitle="LDP Ops Screen" />
      </Router>
    </Layout>
  );
};


export default App;
